import React from 'react';
import './About.css'
import AboutMe from '../../assets/Aboutme.png'
import {AiOutlineBulb} from 'react-icons/ai'

const About = () => {
  return (
    <section id='about'>
      <h5>Get To Know Me</h5>
      <h2>About Me</h2>

      <div className="container about__container">
      <div className="about__me">
        <div className="about__me-image">
          <img src={AboutMe} alt="me" />
        </div>
  </div>

      <div className="about__content">
        <div className="about__cards">
          <article className='about__card'>
            <AiOutlineBulb className='about__icon'/>
            <h5>Experience</h5>
            <small>1+ Years Working</small>
          </article>
        </div>
        <p>As a Frontend Developer I am deeply passionate about creating engaging and user-centric digital experiences. With the ability to turn unique challenges into visually appealing and functionally robust solutions, I am eager to take on new projects. Let's get in touch and bring your ideas to life!</p>
        <div className='center-container'>
<a href="#contact" className='btn btn-primary'>Let's Talk</a>
</div>
      </div>
      </div>
    </section>
  )
}

export default About