import React from 'react'
import './Experience.css'
import {BiLogoHtml5} from 'react-icons/bi'
import {BiLogoCss3} from 'react-icons/bi'
import {BiLogoJavascript} from 'react-icons/bi'
import {BiLogoBootstrap} from 'react-icons/bi'
import {BiLogoReact} from 'react-icons/bi'
import {BiLogoSass} from 'react-icons/bi'
/* import {BiLogoTypescript} from 'react-icons/bi'
import {BiLogoVuejs} from 'react-icons/bi'*/
import {BiLogoJquery} from 'react-icons/bi'
import {BiLogoGit} from 'react-icons/bi'

const Experience = () => {
  return (
    <section id='experience'>
      <h5>The Skills I have</h5>
      <h2>My Experience in Frontend Development</h2>

      <div className="container experience__container">
        <div className="experience__technical">
          <h3>Technical Proficiency</h3>
          <div className="experience__content">
            
            <article className='experience__details'>
              <BiLogoHtml5 className='experience__details-icon'/>
              <div><h4>HTML</h4></div>
            </article>
            
            <article className='experience__details'>
              <BiLogoCss3 className='experience__details-icon'/>
              <div><h4>CSS</h4></div>
            </article>

            <article className='experience__details'>
              <BiLogoJavascript className='experience__details-icon'/>
              <div><h4>Javascript</h4></div>
            </article>

            {/* <article className='experience__details'>
              <BiLogoTypescript className='experience__details-icon'/>
              <div><h4>Typescript</h4></div>
  </article> */}

            <article className='experience__details'>
              <BiLogoSass className='experience__details-icon'/>
              <div><h4>SASS</h4></div>
            </article>
            </div>
            </div>

        
            <div className="experience__other">
              <h3>Libraries, Frameworks & Tools</h3>
              <div className="experience__content">
            <article className='experience__details'>
              <BiLogoReact className='experience__details-icon'/>
              <div><h4>React</h4></div>
            </article>

            {/* <article className='experience__details'>
              <BiLogoVuejs className='experience__details-icon'/>
              <div><h4>VueJS</h4></div>
</article> */}

            <article className='experience__details'>
              <BiLogoBootstrap className='experience__details-icon'/>
              <div><h4>Bootstrap</h4></div>
            </article>

            <article className='experience__details'>
              <BiLogoJquery className='experience__details-icon'/>
              <div><h4>jQuery</h4></div>
            </article>

            <article className='experience__details'>
              <BiLogoGit className='experience__details-icon'/>
              <div><h4>Git</h4></div>
            </article>
            </div>
            </div>
          </div>
      </section>
  )
}

export default Experience