import React, {useState, useEffect} from 'react'
import './Header.css'
import CTA from './CTA'
import ME from '../../assets/me.png'
import HeaderSocials from './HeaderSocials'

const Header = () => {
  const [typedText, setTypedText] = useState('');
  const textToType = "Hello my name is Tatjana Bauer, and I am a Frontend Developer";
  const typingSpeed = 75;
  const typingInterval = 1000;

  useEffect(() => {
    let currentIndex = 0;
    let typingTimer;

    const typeText = () => {
      if (currentIndex < textToType.length) {
        setTypedText((prevText) => prevText + textToType[currentIndex]);
        currentIndex++;
      }
    };

    const startTyping = () => {
      typingTimer = setInterval(typeText, typingSpeed);
    };

    setTimeout(startTyping, typingInterval);

    return () => clearInterval(typingTimer);
  }, []);

  return (
    <header id='header'>
      <div className="container header__container">
        <h2>{typedText}</h2>
        
        <h3 className="text-light">Welcome to my Portfolio Website!</h3>
        <CTA />
        <HeaderSocials />
        

       <div className="me">
          <img src={ME} alt="me" className='picMe' />
    </div>

        <a href="#contact" className='scroll__down'>Scroll Down</a>
      </div>
    </header>
  )
}

export default Header