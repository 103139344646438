import React from 'react'
import './Footer.css'
import {SiLinkedin} from 'react-icons/si'
import {SiGithub} from 'react-icons/si'

const Footer = () => {
  const today = new Date();

  return (
    <footer>
      
      <div className="footer__socials">
        <a href="https://www.linkedin.com/in/tatjanabauer/" target='_blank' rel='noreferrer'><SiLinkedin/></a>
        <a href="https://github.com/Tatjanae" target='_blank' rel='noreferrer'><SiGithub/></a>
      </div>

      <div className="footer__copyright">
        <small>{today.getFullYear()} &copy; Made by <a href="#about">Tatjana Bauer</a>. All rights reserved. | KVK: 92162711</small>
      </div>
    </footer>
  )
}

export default Footer