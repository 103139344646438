import React, {  useState, useRef } from 'react';
import './Contact.css'
import {HiOutlineMail} from 'react-icons/hi'
import {CiLinkedin} from 'react-icons/ci'
import emailjs from 'emailjs-com';


const Contact = () => {
  const form = useRef();
  const [showConfirmation, setShowConfirmation] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_bn83gkp', 'template_54nsdaa', form.current, 'DO80McUSmJTtwDbh6')
      .then(
        (result) => {
          console.log(result.text);
          setShowConfirmation(true);
      }, (error) => {
          console.log(error.text);
      });

      e.target.reset();
  };

  return (
    <section id='contact'>
      <h5>Get In Touch</h5>
      <h2>Contact Me</h2>

      <div className="container contact__container">
        <div className="contact__options">
        <a href="mailto:tatjana-bauer@outlook.com" target='_blank' rel='noreferrer' className="contact__option">
  <article>
    <HiOutlineMail className='contact__option-icon'/>
    <h4>Email</h4>
    <h5>tatjana-bauer@outlook.com</h5>
    <span>Send a message</span>
  </article>
</a>

<a href="https://www.linkedin.com/in/tatjanabauer/" target='_blank' rel='noreferrer' className="contact__option">
  <article>
    <CiLinkedin className='contact__option-icon'/>
    <h4>LinkedIn</h4>
    <h5>Tatjana Bauer</h5>
    <span>Message me on LinkedIn</span>
  </article>
</a>
        </div>
        {/* end of contact options */}
        <form ref={form} onSubmit={sendEmail}>
          <input type="text" name="name" placeholder='Your Full Name' required/>
          <input type="email" name="email" placeholder='Your Email' required/>
          <textarea name="message" rows="7" placeholder='Your Message' required></textarea>
          <button type='submit' className='btn btn-primary'>Send Message</button>
        </form>
        {/* Confirmation Popup */}
      {showConfirmation && (
        <div className="confirmation__popup">
          <p>The Message has been successfully sent!</p>
          <button className='btn close-button btn-primary' onClick={() => setShowConfirmation(false)}>Close</button>
        </div>
      )}
      </div>
    </section>
  )
}

export default Contact