import React from 'react'
import './Projects.css'
import PLACEHOLDER from '../../assets/placeholder.png'
import SKINBOOST from '../../assets/chrome_9F6nmfQwlE.png'
import JELLYFISH from '../../assets/chrome_Ggp7s2JfJc.png'
import DIGITALEDGE from '../../assets/Digital_Edge.png'

//Projects to map through

const data = [
  {
    id: 1,
    image: SKINBOOST,
    title: 'Skinboost Clinic Website',
    demo: 'https://skinboostclinic.nl/'
  },
  {
    id: 2,
    image: JELLYFISH,
    title: 'JellyFish Band Website',
    demo: 'https://band-website-seven.vercel.app/',
    github: 'https://github.com/Tatjanae/band-website',
  },
  {
    id: 3,
    image: DIGITALEDGE,
    title: 'Digital Edge Website',
    demo: 'https://digitaledge.nl'
  },
]

const Projects = () => {
  return (
    <section id='projects'>
      <h5>My Recent Work</h5>
      <h2>Projects</h2>

      <div className="container project__container">
        {
          data.map(({id, image, title, github, demo}) => {
            return (
              <article key={id} className="project__item">
          <div className="project__item-image">
            <img src={image} alt={title} />
          </div>
          <h3>{title}</h3>
          <div className="project__item-cta">
          {github && (
          <a href={github} target='_blank' rel='noreferrer' className='btn'>Github</a> )}
          <a href={demo} target='_blank' rel='noreferrer' className='btn btn-primary'>Live Demo</a>
          </div>
        </article>
            )
          })
        }
      </div>
    </section>
  )
}

export default Projects